/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react';
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
export const Footer = () => {
	const [visible, setVisible] = useState(false);
  
	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 350){
			setVisible(true)
		} 
		else if (scrolled <= 350){
			setVisible(false)
		}
	};

	const scrollToTop = () =>{
		window.scrollTo({
		top: 0, 
		behavior: 'smooth'
		/* you can also use 'auto' behaviour
			in place of 'smooth' */
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisible);

		return () => window.removeEventListener('scroll', toggleVisible)

	}, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
		<>
		<footer className="footer">
            <div className="container-fluid d-flex justify-content-between">
              <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright © IFSPD {currentYear}</span>
            </div>
        </footer>
		</>
    )
}
