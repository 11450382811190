/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react';
// import React , {useState, useEffect} from 'react';
// import { useSelector} from 'react-redux';
// import DropdownButton from 'react-bootstrap/DropdownButton';
// import Dropdown from 'react-bootstrap/Dropdown';
import logoMedium from '../assets/images/logo/logo-medium.png';
// const winWidth = window.innerWidth;

function Header() {
    const windowPath = window.location.pathname.split('/')[1];
    // let oldScrollY = 0;
    // const cartReduxData = useSelector(state => state.stateUpdate);
    // const isAuth = localStorage.getItem("token") ? true : false;
    // //Query Default
    // var defaultKeyword = '';
    // var defaultSelect = 'medicines';
    // // const urlQuryString = window.location.href;
    // const urlPath = window.location.pathname;
    // const urlQuryString = window.location.search
    // const urlParam = new URLSearchParams(urlQuryString);
    // const urlTypeQuery = urlParam.get('keyword');
    // const searchType = urlParam.get('search_type');
    // if(searchType !== null){
    //     defaultSelect = searchType;
    // }else{
    //     const urlPathArray = urlPath.split('/');
    //     if(urlPathArray.length > 1 &&
    //         urlPathArray[1] === 'book-lab-test'
    //     ){
    //         defaultSelect = 'test';
    //     }
    // }
    // console.log(defaultSelect, 'defaultSelect')
    
    // if(urlTypeQuery && urlTypeQuery !== ''){
    //     defaultKeyword = urlTypeQuery;
    // }
    // const [keyword, setKeyword] = useState(defaultKeyword);
    // const [searchSelect, setSearchSelect] = useState(defaultSelect);
    // const [showLogo, setShowLogo] = useState(true);
    // const [dropdownToggle, setDropdownToggle] = useState(false);
    // const searchProduct = (e) => {
    //     e.preventDefault();
    //     if(searchSelect === 'test'){
    //         window.location.href = `/book-lab-test/search?keyword=${keyword}`;
    //     }else{
    //         window.location.href = `/order-medicines/search?keyword=${keyword}`;
    //     }
    // }
    // const updateInput = (e, type) => {
    //     if(type === 'input'){
    //         setKeyword(e.target.value);
    //     }else if(type === 'select'){
    //         setSearchSelect(e.target.value);
    //     }
    // }
    // const showDropdown = (e)=>{
    //     setDropdownToggle(!dropdownToggle);
    // }
    // const hideDropdown = e => {
    //     setDropdownToggle(false);
    // }  
	// const controlHeader = () => {        
    //     oldScrollY = window.scrollY;
    // };

    // const toggleHeaderSty = () => {
    //     const scrolled = document.documentElement.scrollTop;
    //     let selectHeader = document.querySelector('#header');
    //     if(selectHeader){
    //             if (scrolled > 100){
    //                 selectHeader.classList.add('header-scrolled');
    //                 if(winWidth < 400){
    //                     if(window.scrollY > oldScrollY) {
    //                         setShowLogo(false);
    //                     } else {
    //                         setShowLogo(true);
    //                     }
    //                 }
    //             }
    //             // else if (scrolled <= 100){
    //             //     setShowLogo(true);
    //             //     selectHeader.classList.remove('header-scrolled');
    //             // }
    //             else{
    //                 setShowLogo(true);
    //                 selectHeader.classList.remove('header-scrolled');
    //             }
    //     }
        
    // };
    // Toggle Mobile NavBar
    const toggleMobNav = () => {
        try{
            const selectNavBar = document.querySelector('.header-bar');
            const selectMobMenu = document.querySelector('.menu');
            selectNavBar.classList.toggle('close');
            selectMobMenu.classList.toggle('open');
        }catch(e){
            // console.log('error opening mob navbar');
        }
    };
    //Activate Mobile Dropdown
    const activateMobDrop = (evt) => {
        try{
            evt.preventDefault();
            // if (evt.target !== this) return;
            const selectNavBarId = document.querySelector('#scrollbar');
            if(selectNavBarId
                && selectNavBarId.classList.contains('mobile-menu-area-inner')
            ){
                
                // const prvSib = evt.target.previousElementSibling;
                // if(prvSib){
                //     nextSib.classList.toggle('open');
                // }
                const targetTag = evt.target.tagName.toLowerCase();
                if(targetTag === 'span'){
                    evt.preventDefault();
                    // const prevSib = evt.target.previousElementSibling;
                    // // const nextSib = prevSib.nextElementSibling;
                    // prevSib.classList.toggle('dropdown-active');
                    // evt.target.parentElement.find('ul').classList.toggle('dropdown-active');
                }else{
                    const nextSib = evt.target.nextElementSibling;
                    nextSib.classList.toggle('dropdown-active');
                }
                // if(evt.target.classList.contains('dropdown')){
            }
        }catch(e){
            console.log(e);
        }
    }
    useEffect(() => {
        // window.addEventListener('scroll', toggleHeaderSty);
        // window.addEventListener('scroll', controlHeader);
        const selectMobDrop = document.querySelectorAll('#scrollbar .dropdown > a');
        if(selectMobDrop.length){
            for (var i = 0 ; i < selectMobDrop.length; i++) {
                selectMobDrop[i].addEventListener('click', activateMobDrop ) ; 
            }
        }

        return () => {
            // window.removeEventListener('scroll', toggleHeaderSty);
            // window.removeEventListener('scroll', controlHeader);
            if(selectMobDrop.length){
                for (var i = 0 ; i < selectMobDrop.length; i++) {
                    selectMobDrop[i].removeEventListener('click', activateMobDrop ) ; 
                }
            }
        }
  }, []);
  return (
    <>
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a className="navbar-brand brand-logo" href="/"><img src={logoMedium} alt="logo" /></a>
          <a className="navbar-brand brand-logo-mini" href="/"><img src={logoMedium} alt="logo" /></a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
            <span className="mdi mdi-menu"></span>
          </button>
          {/* <div className="search-field d-none d-md-block">
            <form className="d-flex align-items-center h-100" action="#">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <i className="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input type="text" className="form-control bg-transparent border-0" placeholder="Search projects" />
              </div>
            </form>
          </div> */}
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile dropdown">
              {/* <a className="nav-link dropdown-toggle" id="profileDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false"> */}
                {/* <div className="nav-profile-img">
                  <img src="assets/images/faces/face1.jpg" alt="image" />
                  <span className="availability-status online"></span>
                </div> */}
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">Admin IFSPD</p>
                </div>
              {/* </a> */}
              {/* <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-cached me-2 text-success"></i> Activity Log </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-logout me-2 text-primary"></i> Signout </a>
              </div> */}
            </li>
            <li className="nav-item d-none d-lg-block full-screen-link">
              <a className="nav-link">
                <i className="mdi mdi-fullscreen" id="fullscreen-button"></i>
              </a>
            </li>
            {/* <li className="nav-item dropdown">
              <a className="nav-link count-indicator dropdown-toggle" id="messageDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="mdi mdi-email-outline"></i>
                <span className="count-symbol bg-warning"></span>
              </a>
              <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
                <h6 className="p-3 mb-0">Messages</h6>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <img src="assets/images/faces/face4.jpg" alt="image" className="profile-pic" />
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Mark send you a message</h6>
                    <p className="text-gray mb-0"> 1 Minutes ago </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <img src="assets/images/faces/face2.jpg" alt="image" className="profile-pic" />
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Cregh send you a message</h6>
                    <p className="text-gray mb-0"> 15 Minutes ago </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <img src="assets/images/faces/face3.jpg" alt="image" className="profile-pic" />
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Profile picture updated</h6>
                    <p className="text-gray mb-0"> 18 Minutes ago </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center">4 new messages</h6>
              </div>
            </li> */}
            {/* <li className="nav-item dropdown">
              <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-bs-toggle="dropdown">
                <i className="mdi mdi-bell-outline"></i>
                <span className="count-symbol bg-danger"></span>
              </a>
              <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                <h6 className="p-3 mb-0">Notifications</h6>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="mdi mdi-calendar"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">Event today</h6>
                    <p className="text-gray ellipsis mb-0"> Just a reminder that you have an event today </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-warning">
                      <i className="mdi mdi-settings"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">Settings</h6>
                    <p className="text-gray ellipsis mb-0"> Update dashboard </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info">
                      <i className="mdi mdi-link-variant"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">Launch Admin</h6>
                    <p className="text-gray ellipsis mb-0"> New admin wow! </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center">See all notifications</h6>
              </div>
            </li> */}
            <li className="nav-item nav-logout d-none d-lg-block">
              <a className="nav-link" href="/logout">
                <i className="mdi mdi-power"></i>
              </a>
            </li>
            {/* <li className="nav-item nav-settings d-none d-lg-block">
              <a className="nav-link" href="#">
                <i className="mdi mdi-format-line-spacing"></i>
              </a>
            </li> */}
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
    </nav>
    </>
  );
}

export default Header;