import { Footer } from '../footer';
import Header from '../header';
import Sidebar from '../sidebar';

function WrapMaster(props) {
    return (
      <>
        <div className="container-scroller">
        {/* {(typeof props.showHeader === "undefined" || props.showHeader === true) && ( */}
            <Header />
        {/* )} */}
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title text-center"> {props.title} </h3>
                            {/* <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Tables</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Basic tables</li>
                                </ol>
                            </nav> */}
                        </div>
                        {props.children}
                    </div>
                    <Footer />
                </div>
            </div>    
        </div>
      </>
    );
  }
  
  export default WrapMaster;