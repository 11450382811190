import React from "react";
// import { SpinnerRoundFilled } from 'spinners-react';

function LoaderMedium(props){
    return (
        // <SpinnerRoundFilled color={props.color ? props.color: '#d2342d'} size={props.size ? props.size: 45} />
        <></>
    )
}

export {LoaderMedium};