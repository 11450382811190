import React, {useState, useEffect} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { RSstandardStyle } from "../../config/react-select";
import { alertResponseMessage } from "../../actions/action-creators/response";
import picIcon from "../../assets/images/user.png";
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const startYear = currentYear - 100;

function EditFellow(props) {
    const [previewPicImg, setPreviewPicImg] = useState(null);
    const [yearData, setYearData] = useState([]);
    const [programYearData, setProgramYear] = useState([]);
    
    const yearDataList = (type) => {
        const yearItems = [];
        const startingYear = type === 'program' 
                                ? 2020
                                : startYear;
        for (let i = currentYear; i >= startingYear; i--) {
        //   yearItems.push(<option key={`${type}_${i}`} value={i}>{i}</option>);
            yearItems.push({id: i, year: i});
        }
        type === 'program' ? setProgramYear(yearItems) : setYearData(yearItems);
        // return yearItems;
    };
    //Upload Profile Image Preview
    const previewProfilePic = (event) => {
        try{
            const files = event.target.files;
            console.log(files, 'files')
            if(files.length > 0){
            // var selectedAttachFiles = {...this.state.selectedAttachFiles};
            // var lastAppendFileIndex = this.state.lastAppendFileIndex;
            // var error = 0;
            // // const documetAllowedExt = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
            const documetAllowedExt = ["png", "jpg", "jpeg"];
            // // fname.slice((fname.lastIndexOf(".") - 1 >>> 0) + 2)
            // const imageExt = ["image/png", "image/jpg", "image/jpeg"];
            // const allowedSize = 50000;
            const allowedSize = 200000;
            const imgFile = files[0];
            // for(let i=0; i<files.length; i++){
                var ext = imgFile.name.slice((imgFile.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
                console.log('ext', ext)
                //Check for file size and extension
                if(imgFile.size <= allowedSize && documetAllowedExt.includes(ext)){
                    const imgData = {data: imgFile,
                                    src: URL.createObjectURL(imgFile),
                                    ext
                                };
                    setPreviewPicImg({...imgData});
                    props.updateInput('img', 'profile_pic', imgFile);
                }else{
                    setPreviewPicImg(null);
                    props.updateInput('img', 'profile_pic', null);
                    alertResponseMessage({alert_type: 2, message: "Photograph allowed size is 200KB and it must be an image."});
                }
            }
        }catch(e){
            setPreviewPicImg(null);
            props.updateInput('img', 'profile_pic', null);
        }
    }
    // Remove Photo
    const removeProfilePic = (event) => {
        try{
            event.preventDefault();
            if (window.confirm(`Are you sure, you want to remove the photo?`)){
                setPreviewPicImg(null);
                props.updateInput('img', 'profile_pic', null);
            }
        }catch(e){
            setPreviewPicImg(null);
            props.updateInput('img', 'profile_pic', null);
        }
    }
    useEffect(() => {
		//Initial API Data
        // fetchCountryList();
        // fetchSelectList('passion');
        // fetchSelectList('program');
        yearDataList('year');
        yearDataList('program');
	}, []);
    return (
      <>
        <Modal dialogClassName="modal-70w" show={props.show} onHide={props.onHide} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
              <Modal.Title>Edit Fellow Details</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0 table-detail team-detail">
            <div className="col-md-12 col-12 col-sm-12 card p-3">
              {/* <p><b>PROFILE</b></p> */}
                <div className="pb-4">
                    <p className="mb-1 head"><b>Photograph (max 200KB):</b></p>
                    <div className="d-inline-flex">
                        <div className="upload-img">
                            <div className="m-3">
                                <img src={`${process.env.REACT_APP_Image_URL}/fellow/${props.data.pic_name}`} alt={props.data.first_name}className="img-thumbnail" />
                            </div>
                        </div>
                        <div className="p-5"><i className="mdi mdi-24px mdi-arrow-expand-right"></i></div>
                        <div className="upload-img text-center">
                            <div className="m-3">
                                <img src={previewPicImg !== null ? previewPicImg.src : picIcon} alt="User Logo" className="img-thumbnail" />
                            </div>
                            {previewPicImg === null
                            ?
                                <div className="btn btn-light btn-outline-primary p-2">
                                    <span>Upload Photo</span>
                                    <input type="file" className="logo-input" name="profile_pic" onChange={(e) => previewProfilePic(e)} accept="image/x-png,image/jpg,image/jpeg" />
                                </div>
                            :
                                <button className="btn btn-danger p-2" onClick={(e) => removeProfilePic(e)}>Remove Photo</button>
                            }
                        </div>
                    </div>
                </div>
                <div className="pb-4">
                    <p className="mb-1 head"><b>General Details:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <tbody>
                            <tr>
                                <th>First Name:</th>
                                <td><input className="form-control" placeholder="First Name*" name="first_name" autoComplete="off" value={props.data.first_name} onChange={(e) => props.updateInput('input', 'first_name', e.target.value)}/></td>
                            </tr>
                            <tr>
                                <th>Last Name:</th>
                                <td><input className="form-control" placeholder="Last Name" name="last_name" autoComplete="off" value={props.data.last_name} onChange={(e) => props.updateInput('input', 'last_name', e.target.value)}/></td>
                            </tr>
                            <tr>
                                <th>LinkedIn Profile:</th>
                                <td><input className="form-control" name="linkedin_url" value={props.data.linkedin_url !== null ? props.data.linkedin_url : ""} onChange={(e) => props.updateInput('input', 'linkedin_url', e.target.value)} /></td>
                            </tr>
                            <tr>
                                <th>Country:</th>
                                <td>
                                    <Select
                                        options={props.countryData}
                                        defaultValue={{name:props.data.country, id: props.data.country_id}}
                                        placeholder={"Select Country"}
                                        onChange={(value) => props.updateInput('select-react', 'country_id', value)}
                                        getOptionLabel ={(option) => option.name}
                                        getOptionValue ={(option) => option.id}
                                        styles={RSstandardStyle}
                                        menuPlacement="auto"
                                        menuPosition={'fixed'}
                                    />
                                    </td>
                            </tr>
                            <tr>
                                <th>Date of Birth (YYYY-MM-DD):</th>
                                <td><input className="form-control w-200p" type="date" name="dob" max={`${currentYear-10}-12-31`} value={props.data.dob} onChange={(e) => props.updateInput('input', 'dob', e.target.value)}/></td>
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td><input className="form-control" name="email" autoComplete="off" value={props.data.email} onChange={(e) => props.updateInput('input', 'email', e.target.value)}/></td>
                            </tr>
                            <tr>
                                <th>Mobile with Country Code:</th>
                                <td>
                                    <div className="d-flex">
                                    <div className="w-200p">
                                        <Select
                                            options={props.countryData}
                                            defaultValue={{phone_code:props.data.phone_code, id: props.data.mobile_code_id}}
                                            // value={props.data.phone_code_id}
                                            placeholder={"Select Country Code"}
                                            onChange={(value) => props.updateInput('select-react', 'phone_code_id', value)}
                                            getOptionLabel ={(option) => `+${option.phone_code}`}
                                            getOptionValue ={(option) => option.id}
                                            menuPlacement="auto"
                                            menuPosition={'fixed'}
                                            styles={RSstandardStyle}
                                        />
                                    </div>

                                    <input className="form-control ms-1" name="mobile_number" placeholder="Mobile Number" value={props.data.mobile_number} onChange={(e) => props.updateInput('input', 'mobile_number', e.target.value)}/>
                                    </div>
                                </td>
                            </tr>
                            {/* <tr>
                                <th>Gender:</th>
                                <td>{props.data.gender}</td>
                            </tr> */}
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="pb-4">
                    <p className="mb-1 head"><b>IFSPD Program:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <thead>
                            <tr>
                                <th>Program Name</th>
                                <th>Year</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="w-30vw">
                                    <Select 
                                        options={props.programData} 
                                        // defaultValue={formData.ifspd_program_id}
                                        defaultValue={{id: props.data.ifspd_program_id, program_name: props.data.program_name}}
                                        placeholder={"Select Program"}
                                        onChange={(value) => props.updateInput('select-react-single', 'ifspd_program_id', value)}
                                        getOptionLabel ={(option) => option.program_name}
                                        getOptionValue ={(option) => option.id}
                                        menuPlacement="auto"
                                        menuPosition={'fixed'}
                                        styles={RSstandardStyle}
                                    />
                                    </div>
                                </td>
                                <td>
                                    <div className="w-30vw">
                                    <Select 
                                        options={programYearData} 
                                        defaultValue={{year: props.data.ifspd_program_year, id: props.data.ifspd_program_year}}
                                        placeholder={"Select Year"}
                                        onChange={(value) => props.updateInput('select-react-single', 'ifspd_program_year', value)}
                                        getOptionLabel ={(option) => option.year}
                                        getOptionValue ={(option) => option.id}
                                        menuPlacement="auto"
                                        menuPosition={'fixed'}
                                        styles={RSstandardStyle}
                                    />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="pb-4">
                    <p className="mb-1 head"><b>Headline for Profile:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <tbody>
                            <tr>
                                <th className="w-25">Who you are:</th>
                                <td>
                                    <textarea className="form-control" name="position" value={props.data.position} onChange={(e) => props.updateInput('textarea', 'position', e.target.value)} rows={3}/>
                                </td>
                            </tr>
                            {/* <tr>
                                <th>Current designation:</th>
                                <td>{props.data.designation}</td>
                            </tr> */}
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="pb-4">
                    <p className="mb-1 head"><b>Short Profile:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <tbody>
                            <tr>
                                <th className="w-25">A headline of who you are:</th>
                                <td>
                                    <textarea className="form-control" name="headline" value={props.data.headline} onChange={(e) => props.updateInput('textarea', 'headline', e.target.value)}/>
                                </td>
                            </tr>
                            <tr>
                                <th>A sentence about your most important work experience:</th>
                                <td>
                                    <textarea className="form-control" name="imp_exp" value={props.data.experience} onChange={(e) => props.updateInput('textarea', 'experience', e.target.value)} rows={3}/>
                                </td>
                            </tr>
                            <tr>
                                <th>A sentence about your most important education:</th>
                                <td>
                                    <textarea className="form-control" name="imp_edu" value={props.data.education} onChange={(e) => props.updateInput('textarea', 'education', e.target.value)} rows={3}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                {(props.data.experience_details && props.data.experience_details.length > 0) && (
                <div className="pb-4">
                    <p className="mb-1 head"><b>Work Experience:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <thead className="text-center">
                            <tr>
                                <th rowSpan={2}></th>
                                <th rowSpan={2}>Designation</th>
                                <th rowSpan={2}>Organization Name</th>
                                <th rowSpan={2}>Key Responsibilities or Achievements</th>
                                <th colSpan={2}>Date</th>
                            </tr>
                            <tr>
                                <th>Start</th>
                                <th>End</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.experience_details.map((exp_d, index) => (
                                <tr key={`experience_${index}`}>
                                    <td><b>Work Experience {index+1}</b></td>
                                    <td><textarea className="form-control" name={`exp_desig_${index}`} rows={4} value={exp_d.designation} onChange={(e) => props.updateInput('textarea', 'designation', e.target.value, 'array', 'experience_details', index)}/></td>
                                    <td><textarea className="form-control" name={`exp_name_${index}`} rows={4} value={exp_d.organization} onChange={(e) => props.updateInput('textarea', 'organization', e.target.value, 'array', 'experience_details', index)}/></td>
                                    <td><textarea className="form-control" name={`exp_achiev_${index}`} rows={4} value={exp_d.achievement} onChange={(e) => props.updateInput('textarea', 'achievement', e.target.value, 'array', 'experience_details', index)}/></td>
                                    <td>
                                        <div className="w-110p">
                                        <Select
                                            options={yearData} 
                                            defaultValue={exp_d.start_year}
                                            placeholder={"Select Year"}
                                            onChange={(value) => props.updateInput('select-react', 'start_year', value, 'array', 'experience_details', index)}
                                            getOptionLabel ={(option) => option.year}
                                            getOptionValue ={(option) => option.id}
                                            menuPlacement="auto"
                                            menuPosition={'fixed'}
                                            styles={RSstandardStyle}
                                        />
                                        </div>
                                    </td>
                                    <td>
                                        {!exp_d.is_present && (
                                            <>
                                            <div className="w-110p">
                                                <Select
                                                    options={yearData} 
                                                    defaultValue={exp_d.end_year}
                                                    placeholder={"Select Year"}
                                                    onChange={(value) => props.updateInput('select-react', 'end_year', value, 'array', 'experience_details', index)}
                                                    getOptionLabel ={(option) => option.year}
                                                    getOptionValue ={(option) => option.id}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                    styles={RSstandardStyle}
                                                />
                                            </div><br/>
                                            </>
                                        )}
                                        <input className="form-check-input" type="checkbox" value="" id={`exp_present_${index}`} checked={exp_d.is_present} onChange={(e) => props.updateInput('checkbox', 'is_present', e.target.checked, 'array', 'experience_details', index)}/>
                                        <label className="form-check-label" htmlFor={`exp_present_${index}`}>&nbsp;Present</label>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                )}
                {(props.data.education_details && props.data.education_details.length > 0) && (
                <div className="pb-4">
                    <p className="mb-1 head"><b>Education:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <thead className="text-center">
                            <tr>
                                <th rowSpan={2}></th>
                                <th rowSpan={2}>College/Institution Name</th>
                                <th rowSpan={2}>Name of the Program</th>
                                <th rowSpan={2}>Key Achievement or Grade, if any</th>
                                <th colSpan={2}>Date</th>
                            </tr>
                            <tr>
                                <th>Start</th>
                                <th>End</th>
                            </tr>
                        </thead>
                        <tbody>
                        {props.data.education_details.map((edu_d, edu_index) => (
                            <tr key={`education${edu_index}`}>
                                <td><div className="w-100"><b>Education {edu_index+1}</b></div></td>
                                <td><input className="form-control" name={`edu_clg_${edu_index}`} value={edu_d.institute_name} onChange={(e) => props.updateInput('input', 'institute_name', e.target.value, 'array', 'education_details', edu_index)}/></td>
                                <td><input className="form-control" name={`edu_program_${edu_index}`} value={edu_d.program_name} onChange={(e) => props.updateInput('input', 'program_name', e.target.value, 'array', 'education_details', edu_index)}/></td>
                                <td><input className="form-control" name={`edu_achieve_${edu_index}`} value={edu_d.achievement} onChange={(e) => props.updateInput('input', 'achievement', e.target.value, 'array', 'education_details', edu_index)}/></td>
                                <td>
                                    <div className="w-110p">
                                        <Select
                                            options={yearData} 
                                            defaultValue={edu_d.start_year}
                                            placeholder={"Select Year"}
                                            onChange={(value) => props.updateInput('select-react', 'start_year', value, 'array', 'education_details', edu_index)}
                                            getOptionLabel ={(option) => option.year}
                                            getOptionValue ={(option) => option.id}
                                            menuPlacement="auto"
                                            menuPosition={'fixed'}
                                            styles={RSstandardStyle}
                                        />
                                    </div>
                                </td>
                                <td>
                                    {!edu_d.is_present && (
                                    <>
                                    <div className="w-110p">
                                        <Select
                                            options={yearData} 
                                            defaultValue={edu_d.end_year}
                                            placeholder={"Select Year"}
                                            onChange={(value) => props.updateInput('select-react', 'end_year', value, 'array', 'education_details', edu_index)}
                                            getOptionLabel ={(option) => option.year}
                                            getOptionValue ={(option) => option.id}
                                            menuPlacement="auto"
                                            menuPosition={'fixed'}
                                            styles={RSstandardStyle}
                                        />
                                    </div><br/>
                                    </>
                                    )}
                                    <input className="form-check-input" type="checkbox" id={`edu_present_${edu_index}`} value={edu_d.is_present} checked={edu_d.is_present} onChange={(e) => props.updateInput('checkbox', 'is_present', e.target.checked, 'array', 'education_details', edu_index)} />
                                    <label className="form-check-label" htmlFor={`edu_present_${edu_index}`}>&nbsp;Present</label>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                )}
                {(props.data.award_details && props.data.award_details.length > 0) && (
                <div className="pb-4">
                    <p className="mb-1 head"><b>Honors and Awards:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Institution Name</th>
                                <th>Name of the Award</th>
                                <th>Why was this awarded to you?</th>
                                <th>Date (Year)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.award_details.map((award_d, award_index) => (
                            <tr key={`award${award_index}`}>
                                <td><b>Honor or Award {award_index + 1}</b></td>
                                <td><input className="form-control" name={`award_institute_${award_index}`} value={award_d.institute_name} onChange={(e) => props.updateInput('input', 'institute_name', e.target.value, 'array', 'award_details', award_index)} /></td>
                                <td><input className="form-control" name={`award_name${award_index}`} value={award_d.award_name} onChange={(e) => props.updateInput('input', 'award_name', e.target.value, 'array', 'award_details', award_index)} /></td>
                                <td><input className="form-control" name={`award_why${award_index}`} value={award_d.why_awarded} onChange={(e) => props.updateInput('input', 'why_awarded', e.target.value, 'array', 'award_details', award_index)} /></td>
                                <td>
                                    <div className="w-110p">
                                        <Select
                                            options={yearData} 
                                            defaultValue={award_d.year}
                                            placeholder={"Select Year"}
                                            onChange={(value) => props.updateInput('select-react', 'year', value, 'array', 'award_details', award_index)}
                                            getOptionLabel ={(option) => option.year}
                                            getOptionValue ={(option) => option.id}
                                            menuPlacement="auto"
                                            menuPosition={'fixed'}
                                            styles={RSstandardStyle}
                                        />
                                    </div>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                )}
                {(props.data.unique_skills && props.data.unique_skills.length > 0) && (
                <div className="pb-4">
                    <p className="mb-1 head"><b>Unique Skills:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <tbody>
                            {props.data.unique_skills.map((passion_d, passion_index) => (
                            <tr key={`passion${passion_index}`}>
                                <th>Passion Score Top {passion_d.ranking}</th>
                                <td>
                                    <div className="w-200p">
                                        <Select 
                                            options={props.passionData} 
                                            defaultValue={passion_d.passion_id}
                                            label={"Select Passion Score"}
                                            onChange={(value) => props.updateInput('select-react', 'passion_id', value, 'array', 'passion', passion_index)}
                                            getOptionLabel ={(option) => option.title}
                                            getOptionValue ={(option) => option.id}
                                            menuPlacement="auto"
                                            menuPosition={'fixed'}
                                            styles={RSstandardStyle}
                                        />
                                    </div>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                )}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button className="btn btn-gradient-info" onClick={(e) => props.updateFellowData(e)}>
                Submit
            </button>
            {/* <Button variant="secondary" onClick={props.onHide}>
                Close
            </Button> */}
          </Modal.Footer>
      </Modal>
      </>
    );
}
  
export default EditFellow;