import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ViewFellow(props) {
    return (
      <>
        <Modal dialogClassName="modal-70w" show={props.show} onHide={props.onHide}>
          <Modal.Header closeButton>
              <Modal.Title>Fellow Details</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0 table-detail">
            <div className="col-md-12 col-12 col-sm-12 card p-3">
              {/* <p><b>PROFILE</b></p> */}
              <label className={`mb-2 badge ${props.data.is_approved === 1 ? 'badge-success' : 'badge-danger'}`}>{props.data.is_approved === 1 ? 'Approved' : 'Not Approved'}</label>
                <div className="pb-4">
                    <p className="mb-1 head"><b>Photograph:</b></p>
                    <div className="upload-img">
                        <div className="m-3">
                            <img src={`${process.env.REACT_APP_Image_URL}/fellow/${props.data.pic_name}`} alt={props.data.first_name}className="img-thumbnail" />
                        </div>
                    </div>
                </div>
                <div className="pb-4">
                    <p className="mb-1 head"><b>General Details:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <tbody>
                            <tr>
                                <th>Full Name:</th>
                                <td>{props.data.first_name} {props.data.last_name}</td>
                            </tr>
                            <tr>
                                <th>LinkedIn Profile:</th>
                                <td>{(props.data.linkedin_url !== "" && props.data.linkedin_url !== null) && (<a title={props.data.first_name} href={props.data.linkedin_url}>{props.data.linkedin_url}</a>)}</td>
                            </tr>
                            <tr>
                                <th>Country:</th>
                                <td>{props.data.country}</td>
                            </tr>
                            <tr>
                                <th>Date of Birth (YYYY-MM-DD):</th>
                                <td>{props.data.dob}</td>
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td>{props.data.email}</td>
                            </tr>
                            <tr>
                                <th>Mobile with Country Code:</th>
                                <td>+{props.data.phone_code} {props.data.mobile_number}</td>
                            </tr>
                            {/* <tr>
                                <th>Gender:</th>
                                <td>{props.data.gender}</td>
                            </tr> */}
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="pb-4">
                    <p className="mb-1 head"><b>IFSPD Program:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <thead>
                            <tr>
                                <th>Program Name</th>
                                <th>Year</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{props.data.program_name}</td>
                                <td>{props.data.ifspd_program_year}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="pb-4">
                    <p className="mb-1 head"><b>Headline for Profile:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <tbody>
                            <tr>
                                <th>Who you are:</th>
                                <td>{props.data.position}</td>
                            </tr>
                            {/* <tr>
                                <th>Current designation:</th>
                                <td>{props.data.designation}</td>
                            </tr> */}
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="pb-4">
                    <p className="mb-1 head"><b>Short Profile:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <tbody>
                            <tr>
                                <th>A headline of who you are:</th>
                                <td>{props.data.headline}</td>
                            </tr>
                            <tr>
                                <th>A sentence about your most important work experience:</th>
                                <td>{props.data.experience}</td>
                            </tr>
                            <tr>
                                <th>A sentence about your most important education:</th>
                                <td>{props.data.education}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                {(props.data.experience_details && props.data.experience_details.length > 0) && (
                <div className="pb-4">
                    <p className="mb-1 head"><b>Work Experience:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <thead className="text-center">
                            <tr>
                                <th rowSpan={2}></th>
                                <th rowSpan={2}>Designation</th>
                                <th rowSpan={2}>Organization Name</th>
                                <th rowSpan={2}>Key Responsibilities or Achievements</th>
                                <th colSpan={2}>Date</th>
                            </tr>
                            <tr>
                                <th>Start</th>
                                <th>End</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.experience_details.map((exp_d, index) => (
                                <tr key={`experience_${index}`}>
                                    <td><b>Work Experience {index+1}</b></td>
                                    <td>{exp_d.designation}</td>
                                    <td>{exp_d.organization}</td>
                                    <td>{exp_d.achievement}</td>
                                    <td>{exp_d.start_year}</td>
                                    <td>
                                        {(exp_d.start_year !== null)
                                            && <>{exp_d.end_year === null ? 'Present' : exp_d.end_year}</>
                                        }
                                        {/* {exp_d.end_year === null ? 'Present' : exp_d.end_year} */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                )}
                {(props.data.education_details && props.data.education_details.length > 0) && (
                <div className="pb-4">
                    <p className="mb-1 head"><b>Education:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <thead className="text-center">
                            <tr>
                                <th rowSpan={2}></th>
                                <th rowSpan={2}>College/Institution Name</th>
                                <th rowSpan={2}>Name of the Program</th>
                                <th rowSpan={2}>Key Achievement or Grade, if any</th>
                                <th colSpan={2}>Date</th>
                            </tr>
                            <tr>
                                <th>Start</th>
                                <th>End</th>
                            </tr>
                        </thead>
                        <tbody>
                        {props.data.education_details.map((edu_d, edu_index) => (
                            <tr key={`education${edu_index}`}>
                                <td><b>Education {edu_index+1}</b></td>
                                <td>{edu_d.institute_name}</td>
                                <td>{edu_d.program_name}</td>
                                <td>{edu_d.achievement}</td>
                                <td>{edu_d.start_year}</td>
                                <td>
                                    {/* {edu_d.end_year === null ? 'Present' : edu_d.end_year} */}
                                    {(edu_d.start_year !== null)
                                        && <>{edu_d.end_year === null ? 'Present' : edu_d.end_year}</>
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                )}
                {(props.data.award_details && props.data.award_details.length > 0) && (
                <div className="pb-4">
                    <p className="mb-1 head"><b>Honors and Awards:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Institution Name</th>
                                <th>Name of the Award</th>
                                <th>Why was this awarded to you?</th>
                                <th>Date (Year)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.award_details.map((award_d, award_index) => (
                            <tr key={`award${award_index}`}>
                                <td><b>Honor or Award {award_index + 1}</b></td>
                                <td>{award_d.institute_name}</td>
                                <td>{award_d.award_name}</td>
                                <td>{award_d.why_awarded}</td>
                                <td>{award_d.year}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                )}
                {(props.data.unique_skills && props.data.unique_skills.length > 0) && (
                <div className="pb-4">
                    <p className="mb-1 head"><b>Unique Skills:</b></p>
                    <div className="table-responsive ms-2">
                    <table className="border-0 table-bordered table">
                        <tbody>
                            {props.data.unique_skills.map((passion_d, passion_index) => (
                            <tr key={`passion${passion_index}`}>
                                <th>Passion Score Top {passion_d.ranking}</th>
                                <td>{passion_d.title}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                )}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button variant="secondary" onClick={props.onHide}>
                Close
            </Button>
          </Modal.Footer>
      </Modal>
      </>
    );
}
  
export default ViewFellow;